import {
  BaseService,
  Status,
  DataResponse,
  MessageResponse,
  MessageData,
} from '@/services/base.service';
import { Message } from '@/models/message.model';

class MessageService extends BaseService {
  private url: string = '/api/message';

  private cached: Message[] = [];

  constructor() {
    super();
  }

  public new(): Message {
    return {
      id: -1,
      title: '',
      date: '',
      contact: '',
      description: '',
    };
  }

  public async getList(): Promise<DataResponse<Message[]>> {
    let res: any = {
      status: Status.Ok,
      data: [],
    };
    if (!this.hasCache()) {
      res = await this.$http.get<Message[]>(`${this.url}`);
      this.setCache(res.data);
    }
    return {
      status: res.status === Status.Ok,
      data: this.getCache(),
    };
  }

  public async get(id: number): Promise<DataResponse<Message>> {
    const res = await this.$http.get<Message>(`${this.url}/${id}`);
    return {
      status: res.status === Status.Ok,
      data: res.data,
    };
  }

  public async put(message: Message): Promise<DataResponse<Message>> {
    const res = await this.$http.put<Message>(
      `${this.url}/${message.id}`,
      message,
    );
    this.clearCache();
    return {
      status: res.status === Status.Ok,
      data: res.data,
    };
  }

  public async post(message: Message): Promise<DataResponse<Message>> {
    const res = await this.$http.post<Message>(this.url, message);
    this.clearCache();
    return {
      status: res.status === Status.Ok,
      data: res.data,
    };
  }

  public async delete(id: number): Promise<MessageResponse> {
    const res = await this.$http.delete<MessageData>(`${this.url}/${id}`);
    this.clearCache();
    return {
      status: res.status === Status.Ok,
      message: res.data.message,
    };
  }

  private getCache(): Message[] {
    return this.cached;
  }

  private setCache(list: Message[]): void {
    this.cached = list;
  }

  private hasCache(): boolean {
    return this.cached.length !== 0;
  }

  private clearCache(): void {
    this.setCache([]);
  }
}

export default new MessageService();
