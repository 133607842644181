import {
  BaseService,
  Status,
  DataResponse,
  MessageResponse,
  MessageData,
} from '@/services/base.service';
import { Content } from '@/models/content.model';

class ContentService extends BaseService {
  private url: string = '/api/content';

  private cached: Content[] = [];

  constructor() {
    super();
  }

  public async getList(): Promise<DataResponse<Content[]>> {
    let res: any = {
      status: Status.Ok,
      data: [],
    };
    if (!this.hasCache()) {
      res = await this.$http.get<Content[]>(`${this.url}`);
      this.setCache(res.data);
    }
    return {
      status: res.status === Status.Ok,
      data: this.getCache(),
    };
  }

  public async get(id: number): Promise<DataResponse<Content>> {
    const res = await this.$http.get<Content>(`${this.url}/${id}`);
    return {
      status: res.status === Status.Ok,
      data: res.data,
    };
  }

  public async put(content: FormData): Promise<DataResponse<Content>> {
    const res = await this.$http.put<Content>(this.url, content);
    this.clearCache();
    return {
      status: res.status === Status.Ok,
      data: res.data,
    };
  }

  public async post(message: Content): Promise<DataResponse<Content>> {
    const res = await this.$http.post<Content>(this.url, message);
    this.clearCache();
    return {
      status: res.status === Status.Ok,
      data: res.data,
    };
  }

  public async delete(id: number): Promise<MessageResponse> {
    const res = await this.$http.delete<MessageData>(`${this.url}/${id}`);
    this.clearCache();
    return {
      status: res.status === Status.Ok,
      message: res.data.message,
    };
  }

  private getCache(): Content[] {
    return this.cached;
  }

  private setCache(list: Content[]): void {
    this.cached = list;
  }

  private hasCache(): boolean {
    return this.cached.length !== 0;
  }

  private clearCache(): void {
    this.setCache([]);
  }
}

export default new ContentService();
