import {
  BaseService,
  Status,
  DataResponse,
  MessageResponse,
  MessageData,
} from '@/services/base.service';
import { Schedule } from '@/models/schedule.model';

class ScheduleService extends BaseService {
  private url: string = '/api/schedule';

  private cached: Schedule[] = [];

  constructor() {
    super();
  }

  public new(): Schedule {
    return {
      id: -1,
      title: '',
      event: '',
      description: '',
      location: '',
      link: '',
      allDay: false,
      startTime: '',
      endTime: '',
      image: '',
    };
  }

  public async getList(): Promise<DataResponse<Schedule[]>> {
    let res: any = {
      status: Status.Ok,
      data: [],
    };
    if (!this.hasCache()) {
      res = await this.$http.get<Schedule[]>(`${this.url}`);
      this.setCache(res.data);
    }
    return {
      status: res.status === Status.Ok,
      data: this.getCache(),
    };
  }

  public async get(id: number): Promise<DataResponse<Schedule>> {
    const res = await this.$http.get<Schedule>(`${this.url}/${id}`);
    return {
      status: res.status === Status.Ok,
      data: res.data,
    };
  }

  public async put(schedule: Schedule): Promise<DataResponse<Schedule>> {
    const res = await this.$http.put<Schedule>(this.url, schedule);
    this.clearCache();
    return {
      status: res.status === Status.Ok,
      data: res.data,
    };
  }

  public async post(schedule: Schedule): Promise<DataResponse<Schedule>> {
    const res = await this.$http.post<Schedule>(this.url, schedule);
    this.clearCache();
    return {
      status: res.status === Status.Ok,
      data: res.data,
    };
  }

  public async delete(id: number): Promise<MessageResponse> {
    const res = await this.$http.delete<MessageData>(`${this.url}/${id}`);
    this.clearCache();
    return {
      status: res.status === Status.Ok,
      message: res.data.message,
    };
  }

  private getCache(): Schedule[] {
    return this.cached;
  }

  private setCache(list: Schedule[]): void {
    this.cached = list;
  }

  private hasCache(): boolean {
    return this.cached.length !== 0;
  }

  private clearCache(): void {
    this.setCache([]);
  }
}

export default new ScheduleService();
