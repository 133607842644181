import { format } from 'date-fns';

export const formatDate = (value: number | string): string =>
  format(new Date(value), 'dd-MM-yyyy');

export const formatTime = (value: number | string): string =>
  format(new Date(value), 'HH:mm');

export const formatHours = (value: number): string => value.toFixed(2);
export const formatVAT = (vat: number) => `${Math.round(vat * 100)}%`;
export const formatCurrency = (
  value: number,
  down: boolean = false,
): string => {
  value = down ? Math.floor(value * 100) / 100 : Math.ceil(value * 100) / 100;
  let newValue = parseFloat(`${value}`).toLocaleString('nl-nl');

  if (newValue.indexOf(',') === -1) {
    newValue += ',00';
  } else if (newValue.substring(newValue.indexOf(',')).length === 2) {
    newValue += '0';
  }

  return newValue;
};

export const capitalize = (str: string): string =>
  `${str.substring(0, 1).toUpperCase()}${str.substring(1)}`;
export const upper = (str: string): string => str.toUpperCase();
export const lower = (str: string): string => str.toLowerCase();
