import { createApp } from 'vue';
import axios from 'axios';
import router from './router';
import store from './store';
import BazaarApp from './BazaarApp.vue';
import AutoFocus from './core/directives/auto-focus';
import ClickOutside from './core/directives/click-outside';

axios.interceptors.response.use(undefined, (err) => {
  if (err.response.status === 401) {
    document.location.href = '/';
  }
});

const app = createApp(BazaarApp).use(router).use(store);

app.directive('auto-focus', AutoFocus);
app.directive('click-outside', ClickOutside);

app.mount('#app');
