import { required } from '@vuelidate/validators';

export interface Message {
  id: number;
  date: string;
  title: string;
  description: string;
  contact: string;
}

export const messageValidation = {
  date: {
    required,
  },
  title: {
    required,
  },
  description: {
    required,
  },
  contact: {},
};

export const sortMessages = (a: Message, b: Message) =>
  Date.parse(b.date) - Date.parse(a.date);
