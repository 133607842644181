import {
  BaseService,
  Status,
  DataResponse,
  MessageResponse,
  MessageData,
} from '@/services/base.service';
import { User } from '@/models/user.model';

class UserService extends BaseService {
  private url: string = '/api/user';

  private cached: User[] = [];

  constructor() {
    super();
  }

  public new(): User {
    return {
      id: -1,
      name: '',
    };
  }

  public async getList(): Promise<DataResponse<User[]>> {
    let res: any = {
      status: Status.Ok,
      data: [],
    };
    if (!this.hasCache()) {
      res = await this.$http.get<User[]>(`${this.url}`);
      this.setCache(res.data);
    }
    return {
      status: res.status === Status.Ok,
      data: this.getCache(),
    };
  }

  public async get(id: number): Promise<DataResponse<User>> {
    const res = await this.$http.get<User>(`${this.url}/${id}`);
    return {
      status: res.status === Status.Ok,
      data: res.data,
    };
  }

  public async put(message: User): Promise<DataResponse<User>> {
    const res = await this.$http.put<User>(
      `${this.url}/${message.id}`,
      message,
    );
    this.clearCache();
    return {
      status: res.status === Status.Ok,
      data: res.data,
    };
  }

  public async post(message: User): Promise<DataResponse<User>> {
    const res = await this.$http.post<User>(this.url, message);
    this.clearCache();
    return {
      status: res.status === Status.Ok,
      data: res.data,
    };
  }

  public async delete(id: number): Promise<MessageResponse> {
    const res = await this.$http.delete<MessageData>(`${this.url}/${id}`);
    this.clearCache();
    return {
      status: res.status === Status.Ok,
      message: res.data.message,
    };
  }

  private getCache(): User[] {
    return this.cached;
  }

  private setCache(list: User[]): void {
    this.cached = list;
  }

  private hasCache(): boolean {
    return this.cached.length !== 0;
  }

  private clearCache(): void {
    this.setCache([]);
  }
}

export default new UserService();
