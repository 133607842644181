<template>
  <svg class="loading" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="20px" height="20px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
    <g transform="rotate(0 50 50)">
      <rect x="41.5" y="0" rx="8.5" ry="21" width="17" height="42" fill="#abbd81">
        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1.1764705882352942s" begin="-1.0859728506787332s" repeatCount="indefinite"></animate>
      </rect>
    </g><g transform="rotate(27.692307692307693 50 50)">
      <rect x="41.5" y="0" rx="8.5" ry="21" width="17" height="42" fill="#abbd81">
        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1.1764705882352942s" begin="-0.9954751131221721s" repeatCount="indefinite"></animate>
      </rect>
    </g><g transform="rotate(55.38461538461539 50 50)">
      <rect x="41.5" y="0" rx="8.5" ry="21" width="17" height="42" fill="#abbd81">
        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1.1764705882352942s" begin="-0.904977375565611s" repeatCount="indefinite"></animate>
      </rect>
    </g><g transform="rotate(83.07692307692308 50 50)">
      <rect x="41.5" y="0" rx="8.5" ry="21" width="17" height="42" fill="#abbd81">
        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1.1764705882352942s" begin="-0.8144796380090499s" repeatCount="indefinite"></animate>
      </rect>
    </g><g transform="rotate(110.76923076923077 50 50)">
      <rect x="41.5" y="0" rx="8.5" ry="21" width="17" height="42" fill="#abbd81">
        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1.1764705882352942s" begin="-0.7239819004524888s" repeatCount="indefinite"></animate>
      </rect>
    </g><g transform="rotate(138.46153846153845 50 50)">
      <rect x="41.5" y="0" rx="8.5" ry="21" width="17" height="42" fill="#abbd81">
        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1.1764705882352942s" begin="-0.6334841628959277s" repeatCount="indefinite"></animate>
      </rect>
    </g><g transform="rotate(166.15384615384616 50 50)">
      <rect x="41.5" y="0" rx="8.5" ry="21" width="17" height="42" fill="#abbd81">
        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1.1764705882352942s" begin="-0.5429864253393666s" repeatCount="indefinite"></animate>
      </rect>
    </g><g transform="rotate(193.84615384615384 50 50)">
      <rect x="41.5" y="0" rx="8.5" ry="21" width="17" height="42" fill="#abbd81">
        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1.1764705882352942s" begin="-0.4524886877828055s" repeatCount="indefinite"></animate>
      </rect>
    </g><g transform="rotate(221.53846153846155 50 50)">
      <rect x="41.5" y="0" rx="8.5" ry="21" width="17" height="42" fill="#abbd81">
        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1.1764705882352942s" begin="-0.3619909502262444s" repeatCount="indefinite"></animate>
      </rect>
    </g><g transform="rotate(249.23076923076923 50 50)">
      <rect x="41.5" y="0" rx="8.5" ry="21" width="17" height="42" fill="#abbd81">
        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1.1764705882352942s" begin="-0.2714932126696833s" repeatCount="indefinite"></animate>
      </rect>
    </g><g transform="rotate(276.9230769230769 50 50)">
      <rect x="41.5" y="0" rx="8.5" ry="21" width="17" height="42" fill="#abbd81">
        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1.1764705882352942s" begin="-0.1809954751131222s" repeatCount="indefinite"></animate>
      </rect>
    </g><g transform="rotate(304.61538461538464 50 50)">
      <rect x="41.5" y="0" rx="8.5" ry="21" width="17" height="42" fill="#abbd81">
        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1.1764705882352942s" begin="-0.0904977375565611s" repeatCount="indefinite"></animate>
      </rect>
    </g><g transform="rotate(332.3076923076923 50 50)">
      <rect x="41.5" y="0" rx="8.5" ry="21" width="17" height="42" fill="#abbd81">
        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1.1764705882352942s" begin="0s" repeatCount="indefinite"></animate>
      </rect>
    </g>
  </svg>
</template>
