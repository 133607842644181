import { required } from '@vuelidate/validators';

export interface Sponsor {
  id: number;
  name: string;
  website: string;
  main: boolean;
  lottery: boolean;
  contribution: string;
  image?: string;
}

export interface SponsorInput {
  id: number;
  name: string;
  website: string;
  main: boolean;
  lottery: boolean;
  contribution: string;
  image?: File;
}

export const sponsorValidation = {
  name: {
    required,
  },
  website: {
    required,
  },
};
