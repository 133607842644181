import { required } from '@vuelidate/validators';

export interface Content {
  id: number;
  title: string;
  slug: string;
  content: string;
  image?: string;
  user?: number;
}

export interface ContentInput {
  id: number;
  title: string;
  slug?: string;
  content: string;
  image?: File;
  user?: number;
}

export const contentValidation = {
  title: {
    required,
  },
  content: {
    required,
  },
};
