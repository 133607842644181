import { ActionContext, createStore } from 'vuex';
// models
import { Actions, BazaarState, Mutations } from './models/store.model';
import { Auth, defaultAuth, LoginData } from './models/auth.model';
import { Message, sortMessages } from './models/message.model';
import { Schedule } from './models/schedule.model';
import { Content } from './models/content.model';
import { Sponsor } from './models/sponsor.model';
import { FAQ } from './models/faq.model';
import { User } from './models/user.model';
// services
import authService from './services/auth.service';
import messageService from './services/message.service';
import scheduleService from './services/schedule.service';
import contentService from './services/content.service';
import faqService from './services/faq.service';
import userService from './services/user.service';
import sponsorService from './services/sponsor.service';
// modules
import { drawerStore } from './core/drawer.store';

type Context = ActionContext<BazaarState, BazaarState>;

export default createStore<BazaarState>({
  state: {
    auth: defaultAuth,
    messages: [],
    scheduleItem: null,
    schedule: [],
    messageItem: null,
    contents: [],
    contentItem: null,
    sponsors: [],
    sponsorItem: null,
    faqs: [],
    faqItem: null,
    users: [],
  },
  modules: {
    ds: drawerStore,
  },
  mutations: {
    [Mutations.SetAuth](state: BazaarState, auth: Auth) {
      state.auth = auth;
    },
    [Mutations.SetMessages](state: BazaarState, messages: Message[]) {
      state.messages = messages;
    },
    [Mutations.SetMessageItem](state: BazaarState, message: Message) {
      state.messageItem = message;
    },
    [Mutations.SetSchedules](state: BazaarState, schedules: Schedule[]) {
      state.schedule = schedules;
    },
    [Mutations.SetScheduleItem](state: BazaarState, schedule: Schedule) {
      state.scheduleItem = schedule;
    },
    [Mutations.SetContents](state: BazaarState, contents: Content[]) {
      state.contents = contents;
    },
    [Mutations.SetContentItem](state: BazaarState, content: Content) {
      state.contentItem = content;
    },
    [Mutations.SetSponsors](state: BazaarState, sponsors: Sponsor[]) {
      state.sponsors = sponsors;
    },
    [Mutations.SetSponsorItem](state: BazaarState, sponsor: Sponsor) {
      state.sponsorItem = sponsor;
    },
    [Mutations.SetFAQs](state: BazaarState, faqs: FAQ[]) {
      state.faqs = faqs;
    },
    [Mutations.SetFAQItem](state: BazaarState, faq: FAQ) {
      state.faqItem = faq;
    },
    [Mutations.SetUsers](state: BazaarState, users: User[]) {
      state.users = users;
    },
  },
  actions: {
    async [Actions.GetAuth]({ commit }: Context): Promise<Auth | null> {
      const response = await authService.get();
      commit(Mutations.SetAuth, response.data);

      return response.data ? response.data : null;
    },
    async [Actions.Login](
      { commit }: Context,
      login: LoginData,
    ): Promise<boolean> {
      const response = await authService.login(login);

      commit(Mutations.SetAuth, response.data);

      return response.status;
    },
    async [Actions.Logout]({ commit }: Context) {
      await authService.logout();

      commit(Mutations.SetAuth, defaultAuth);
    },
    async [Actions.GetMessageItem]({ commit }: Context, id: number) {
      const response = await messageService.get(id);
      commit(Mutations.SetMessageItem, response.data);
    },
    async [Actions.GetMessages]({ commit }: Context) {
      const response = await messageService.getList();
      commit(Mutations.SetMessages, response.data);
    },
    async [Actions.AddMessage]({ dispatch }: Context, message: Message) {
      await messageService.post(message);
      dispatch(Actions.GetMessages);
    },
    async [Actions.UpdateMessage]({ dispatch }: Context, message: Message) {
      await messageService.put(message);
      dispatch(Actions.GetMessages);
    },
    async [Actions.GetScheduleItem]({ commit }: Context, id: number) {
      const response = await scheduleService.get(id);
      commit(Mutations.SetScheduleItem, response.data);
    },
    async [Actions.GetSchedules]({ commit }: Context) {
      const response = await scheduleService.getList();
      commit(Mutations.SetSchedules, response.data);
    },
    async [Actions.AddSchedule]({ dispatch }: Context, schedule: Schedule) {
      await scheduleService.post(schedule);
      dispatch(Actions.GetSchedules);
    },
    async [Actions.UpdateSchedule]({ dispatch }: Context, schedule: Schedule) {
      await scheduleService.put(schedule);
      dispatch(Actions.GetSchedules);
    },
    async [Actions.GetContentItem]({ commit }: Context, id: number) {
      const response = await contentService.get(id);
      commit(Mutations.SetContentItem, response.data);
    },
    async [Actions.GetContents]({ commit }: Context) {
      const response = await contentService.getList();
      commit(Mutations.SetContents, response.data);
    },
    async [Actions.AddContent]({ dispatch }: Context, content: Content) {
      await contentService.post(content);
      dispatch(Actions.GetContents);
    },
    async [Actions.UpdateContent]({ dispatch }: Context, content: FormData) {
      await contentService.put(content);
      dispatch(Actions.GetContents);
    },
    async [Actions.GetSponsorItem]({ commit }: Context, id: number) {
      const response = await sponsorService.get(id);
      commit(Mutations.SetSponsorItem, response.data);
    },
    async [Actions.GetSponsors]({ commit }: Context) {
      const response = await sponsorService.getList();
      commit(Mutations.SetSponsors, response.data);
    },
    async [Actions.GetSponsorItem]({ commit }: Context, id: number) {
      const response = await sponsorService.get(id);
      commit(Mutations.SetSponsorItem, response.data);
    },
    async [Actions.AddSponsor]({ dispatch }: Context, sponsor: Sponsor) {
      await sponsorService.post(sponsor);
      dispatch(Actions.GetSponsors);
    },
    async [Actions.UpdateSponsor]({ dispatch }: Context, sponsor: Sponsor) {
      await sponsorService.put(sponsor);
      dispatch(Actions.GetSponsors);
    },
    async [Actions.GetFAQItem]({ commit }: Context, id: number) {
      const response = await faqService.get(id);
      commit(Mutations.SetFAQItem, response.data);
    },
    async [Actions.GetFAQs]({ commit }: Context) {
      const response = await faqService.getList();
      commit(Mutations.SetFAQs, response.data);
    },
    async [Actions.AddFAQ]({ dispatch }: Context, faq: FAQ) {
      await faqService.post(faq);
      dispatch(Actions.GetFAQs);
    },
    async [Actions.UpdateFAQ]({ dispatch }: Context, faq: FAQ) {
      await faqService.put(faq);
      dispatch(Actions.GetFAQs);
    },
    async [Actions.GetUsers]({ commit }: Context) {
      const response = await userService.getList();
      commit(Mutations.SetUsers, response.data);
    },
  },
  getters: {
    getContent: (state) => (slug: string) =>
      state.contents.find((c) => c.slug === slug),
    latestMessages: (state) =>
      [...state.messages.sort(sortMessages)].slice(0, 3),
    faqTop: (state) => [...state.faqs.sort((a, b) => a.id - b.id)].slice(0, 2),
  },
});
