import {
  BaseService,
  Status,
  DataResponse,
  MessageResponse,
  MessageData,
} from '@/services/base.service';
import { Auth, defaultAuth, LoginData } from '@/models/auth.model';

class AuthService extends BaseService {
  private url: string = '/api/auth';

  constructor() {
    super();
  }

  public async get(): Promise<DataResponse<Auth | null>> {
    const res = await this.$http.get<Auth>(this.url);
    return {
      status: res && res.status === Status.Ok,
      data: res ? res.data : defaultAuth,
    };
  }

  public async login(auth: LoginData): Promise<DataResponse<Auth>> {
    const res = await this.$http.post<Auth>(`${this.url}/login`, auth);

    return {
      status: res && res.status === Status.Ok,
      data: res ? res.data : defaultAuth,
    };
  }

  public async logout(): Promise<MessageResponse> {
    const res = await this.$http.get<MessageData>(`${this.url}/logout`);
    return {
      status: res.status === Status.Ok,
      message: res.data.message,
    };
  }
}

export default new AuthService();
