export interface LoginData {
  user_email: string;
  user_password: string;
}

export interface Auth {
  expires: string;
  isAnonymous: boolean;
  isUser: boolean;
  isAdmin: boolean;
}

export const defaultAuth: Auth = {
  expires: '',
  isAnonymous: true,
  isUser: false,
  isAdmin: false,
};
