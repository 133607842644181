import {
  BaseService,
  Status,
  DataResponse,
  MessageResponse,
  MessageData,
} from '@/services/base.service';
import { FAQ } from '@/models/faq.model';

class FAQService extends BaseService {
  private url: string = '/api/faq';

  private cached: FAQ[] = [];

  constructor() {
    super();
  }

  public new(): FAQ {
    return {
      id: -1,
      question: '',
      answer: '',
      contact: '',
      user: -1,
    };
  }

  public async getList(): Promise<DataResponse<FAQ[]>> {
    let res: any = {
      status: Status.Ok,
      data: [],
    };
    if (!this.hasCache()) {
      res = await this.$http.get<FAQ[]>(`${this.url}`);
      this.setCache(res.data);
    }
    return {
      status: res.status === Status.Ok,
      data: this.getCache(),
    };
  }

  public async get(id: number): Promise<DataResponse<FAQ>> {
    const res = await this.$http.get<FAQ>(`${this.url}/${id}`);
    return {
      status: res.status === Status.Ok,
      data: res.data,
    };
  }

  public async put(message: FAQ): Promise<DataResponse<FAQ>> {
    const res = await this.$http.put<FAQ>(`${this.url}/${message.id}`, message);
    this.clearCache();
    return {
      status: res.status === Status.Ok,
      data: res.data,
    };
  }

  public async post(message: FAQ): Promise<DataResponse<FAQ>> {
    const res = await this.$http.post<FAQ>(this.url, message);
    this.clearCache();
    return {
      status: res.status === Status.Ok,
      data: res.data,
    };
  }

  public async delete(id: number): Promise<MessageResponse> {
    const res = await this.$http.delete<MessageData>(`${this.url}/${id}`);
    this.clearCache();
    return {
      status: res.status === Status.Ok,
      message: res.data.message,
    };
  }

  private getCache(): FAQ[] {
    return this.cached;
  }

  private setCache(list: FAQ[]): void {
    this.cached = list;
  }

  private hasCache(): boolean {
    return this.cached.length !== 0;
  }

  private clearCache(): void {
    this.setCache([]);
  }
}

export default new FAQService();
