<script lang="ts" setup></script>
<template>
  <nav>
    <ul>
      <li>
        <RouterLink to="/">Home</RouterLink>
      </li>
      <li>
        <RouterLink to="/berichten">Berichten</RouterLink>
      </li>
      <li>
        <RouterLink to="/programma">Programma</RouterLink>
      </li>
    </ul>
  </nav>
</template>
