import { Schedule } from './schedule.model';
import { Message } from './message.model';
import { Auth } from './auth.model';
import { Content } from './content.model';
import { FAQ } from './faq.model';
import { User } from './user.model';
import { Sponsor } from './sponsor.model';

export interface BazaarState {
  auth: Auth;
  schedule: Schedule[];
  scheduleItem: Schedule | null;
  messages: Message[];
  messageItem: Message | null;
  contents: Content[];
  contentItem: Content | null;
  sponsors: Sponsor[];
  sponsorItem: Sponsor | null;
  faqs: FAQ[];
  faqItem: FAQ | null;
  users: User[];
}

export enum Mutations {
  SetAuth = 'SetAuth',
  SetMessages = 'SetMessages',
  SetMessageItem = 'SetMessageItem',
  SetSchedules = 'SetSchedules',
  SetScheduleItem = 'SetScheduleItem',
  SetContents = 'SetContents',
  SetContentItem = 'SetContentItem',
  SetSponsors = 'SetSponsors',
  SetSponsorItem = 'SetSponsorItem',
  SetFAQs = 'SetFAQs',
  SetFAQItem = 'SetFAQItem',
  SetUsers = 'SetUsers',
}

export enum Actions {
  Login = 'Login',
  Logout = 'Logout',
  GetAuth = 'GetAuth',
  // schedules
  GetSchedules = 'GetSchedules',
  GetScheduleItem = 'GetScheduleItem',
  AddSchedule = 'AddSchedule',
  UpdateSchedule = 'UpdateSchedule',
  // messages
  GetMessages = 'GetMessages',
  GetMessageItem = 'GetMessageItem',
  AddMessage = 'AddMessage',
  UpdateMessage = 'UpdateMessage',
  // content
  GetContents = 'GetContents',
  GetContentItem = 'GetContentItem',
  AddContent = 'AddContent',
  UpdateContent = 'UpdateContent',
  // faq
  GetFAQs = 'GetFAQs',
  GetFAQItem = 'GetFAQItem',
  AddFAQ = 'AddFAQ',
  UpdateFAQ = 'UpdateFAQ',
  // sponsors
  GetSponsors = 'GetSponsors',
  GetSponsorItem = 'GetSponsorItem',
  AddSponsor = 'AddSponsor',
  UpdateSponsor = 'UpdateSponsor',
  // user
  GetUsers = 'GetUsers',
}
