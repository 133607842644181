import { required, requiredIf } from '@vuelidate/validators';

export interface Schedule {
  id: number;
  event: string;
  startTime: string;
  endTime: string;
  allDay: boolean;
  location: string;
  link: string;
  title: string;
  description: string;
  image?: string;
}

export interface ScheduleInput {
  id: number;
  event: string;
  startTime: string;
  endTime: string;
  allDay: boolean;
  location: string;
  link: string;
  title: string;
  description: string;
  image?: File;
}

export const scheduleValidation = {
  event: {
    required,
  },
  startTime: {
    required: requiredIf(function () {
      return !arguments[1].allDay;
    }),
  },
  endTime: {
    required: requiredIf(function () {
      return !arguments[1].allDay;
    }),
  },
  location: {
    required,
  },
  title: {},
  description: {
    required,
  },
};
