import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import HomePage from './pages/HomePage.vue';
import LoginPage from './pages/LoginPage.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomePage,
    meta: {
      title: 'Bazaar 2023 Vrijeschool Mareland',
    },
  },
  {
    path: '/login',
    name: 'login',
    component: LoginPage,
    meta: {
      title: 'Inloggen - Bazaar 2023',
    },
  },
  {
    path: '/programma',
    name: 'programma',
    component: () =>
      import(/* webpackChunkName: "messages" */ './pages/SchedulePage.vue'),
    meta: {
      title: 'Programma - Bazaar 2023',
    },
  },
  {
    path: '/berichten',
    name: 'berichten',
    component: () =>
      import(/* webpackChunkName: "messages" */ './pages/MessagesPage.vue'),
    meta: {
      title: 'Berichten - Bazaar 2023',
    },
  },
  {
    path: '/veelgestelde-vragen',
    name: 'veelgestelde-vragen',
    component: () =>
      import(/* webpackChunkName: "messages" */ './pages/FAQPage.vue'),
    meta: {
      title: 'Veelgestelde vragen - Bazaar 2023',
    },
  },
  {
    path: '/activiteiten',
    name: 'activiteiten',
    component: () =>
      import(/* webpackChunkName: "messages" */ './pages/ClassesPage.vue'),
    meta: {
      title: 'Activiteiten in de klassen - Bazaar 2023',
    },
  },
  {
    path: '/sponsors',
    name: 'sponsors',
    component: () =>
      import(/* webpackChunkName: "messages" */ './pages/SponsorsPage.vue'),
    meta: {
      title: 'Sponsors - Bazaar 2023',
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  linkActiveClass: 'active', // active class for non-exact links.
  linkExactActiveClass: 'active active-exact',
  routes,
});

router.beforeEach((to, from, next) => {
  // Get the page title from the route meta data that we have defined
  // See further down below for how we setup this data
  const title: string = to.meta.title as string;
  // If the route has a title, set it as the page title of the document/page
  if (title) {
    document.title = title;
  }
  // Continue resolving the route
  next();
});

export default router;
